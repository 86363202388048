body {
  @apply antialiased;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body.with-modals {
  @apply overflow-hidden;
}

body {
  &.lighter {
    background: var(--brand-color--med);
  }

  &.player {
    text-align: center;
  }

  &.embed {
    background: var(--brand-color--faint);
    margin: 0;
    padding-bottom: 0;

    .container {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  &.admin {
    background: var(--brand-color--med);
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  &.error {
    position: absolute;
    text-align: center;
    color: var(--primary-text-color--faint);
    background: var(--brand-color--med);
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog {
      vertical-align: middle;
      margin: 20px;

      h1 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
}

// Note: this is needed for React HotKeys performance. Removing this
// will cause severe performance degradation on Safari.
div[tabindex="-1"]:focus {
  outline: 0;
}

::selection {
  @apply bg-primary-600 text-white;
}

noscript {
  text-align: center;

  img {
    width: 200px;
    opacity: 0.5;
    animation: flicker 4s infinite;
  }

  div {
    font-size: 14px;
    margin: 30px auto;
    color: var(--primary-text-color--faint);
    max-width: 400px;

    a {
      color: var(--highlight-text-color);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.floating-link {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 201;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.greentext {
  color: #789922;
}
